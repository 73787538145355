import { Separator } from '@/components/ui/separator'
import {
    TypographyLarge,
    TypographyP,
    TypographyXS,
} from '@/components/ui/typography'
import { useCostHistoryQuery } from '@/queries/useHistoryQuery'
import { useParams } from 'react-router-dom'
import HistorySkeleton from './components/HistorySkeleton'
import HistoryItem from './components/HistoryItem'
import { format, isToday, isYesterday, parseISO } from 'date-fns'
import HistoryDetailDialog from './components/HistoryDetailDialog'

const HistorySection = () => {
    const { id } = useParams()
    const { data } = useCostHistoryQuery({ structureId: id! })

    if (!data) return <HistorySkeleton />

    const changesByDate = Object.entries(data.MUDANCAS_POR_DATA)

    return (
        <div className="w-[340px] xl:w-[420px] flex flex-col p-6 bg-background rounded-lg shadow-lg overflow-hidden">
            <div className="space-y-2">
                <div className="flex items-center justify-between">
                    <TypographyLarge>Histórico custo</TypographyLarge>
                    <HistoryDetailDialog />
                </div>
                <Separator />
            </div>
            <div className="flex-1 overflow-hidden overflow-y-auto">
                {changesByDate.length > 0 ? (
                    changesByDate
                        .sort(([dateA], [dateB]) => {
                            return (
                                parseISO(dateB).getTime() -
                                parseISO(dateA).getTime()
                            )
                        })
                        .map(([date, history]) => {
                            const dateObject = parseISO(date)
                            let dateLocale = format(dateObject, 'dd/MM/yyyy')

                            if (isYesterday(dateObject)) {
                                dateLocale = 'Ontem'
                            } else if (isToday(dateObject)) {
                                dateLocale = 'Hoje'
                            }

                            return (
                                <div className="relative" key={date}>
                                    <div className="sticky flex items-center justify-center my-2 top-2">
                                        <div className="px-2 py-1 rounded-full shadow shrink-0 bg-primary-50 dark:bg-primary-400/30">
                                            <TypographyXS className="text-primary-700 dark:text-primary">
                                                {dateLocale}
                                            </TypographyXS>
                                        </div>
                                    </div>
                                    {history.length > 0 ? (
                                        history.map((cost, index) => {
                                            return (
                                                <HistoryItem
                                                    key={index}
                                                    item={cost}
                                                />
                                            )
                                        })
                                    ) : (
                                        <div>
                                            <TypographyP>
                                                Não há histórico para esse
                                                produto
                                            </TypographyP>
                                        </div>
                                    )}
                                </div>
                            )
                        })
                ) : (
                    <div className="py-2">
                        <TypographyP className="text-muted-foreground">
                            Não há histórico para esse produto
                        </TypographyP>
                    </div>
                )}
            </div>
        </div>
    )
}

export default HistorySection
